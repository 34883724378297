import "./services.scss";

import React, { useState } from "react";
import Background from "../../assets/images/MG_0671-Panorama.jpg";

import Montage from "../../assets/icons/icon-montage.svg";
import Outsourcing from "../../assets/icons/icon-outsourcing.svg";
import Verpackung from "../../assets/icons/icon-verpackung.svg";
import zerspanen from "../../assets/icons/icon-zerspanen.svg";

const text_1 = (
  <p>
    Die moderne Zerspanung entwickelt sich ständig weiter. Unser
    Unternehmen behält wichtige neue Trends, zum Beispiel im
    Bereich der Werkstoffe oder Industriedienstleistungen, im Blick.
    Damit bleiben wir immer auf dem neuesten Stand der Technik. In
    unserer Fertigung setzen wir CNC-Fräs.- und NC-Drehmaschinen
    ein, deren moderne Steuerungstechnik in der Lage ist, Bauteile
    mit höchster Wiederholgenauigkeit herzustellen. Für Bohrungen
    verwenden wir Standbohrmaschinen, mit denen wir durch die
    Kombination aus Linearführung und Gewindespindel auch
    schwere Bauteile bearbeiten können. Das Erreichen höchster

    Genauigkeit und perfekter Oberflächenqualitäten ist dabei unser
    Anspruch.
  </p>
);

const text_2 = (
  <p>
    Die Sichtprüfung aller Teile und die Selektion normgerechter und
    nicht normgerechter Bauteile vor und nach der Bearbeitung ist
    wesentlicher Bestandteil unserer Qualitätsprüfung nach ISO
    9001:2015. Dabei ist es uns wichtig, dass die Prüfungen den
    höchsten Standards entsprechen.
    In separaten Arbeitsbereichen kontrollieren unsere Mitarbeiter
    die Produkte gemäß Ihren Vorgaben (Prüfanweisungen und
    Zeichnungen). Mit der Erfassung von Ausschussmerkmalen
    unterstützen wir Sie in Ihrer Prozessoptimierung.
    Hierbei steht unsere Unternehmensphilosophie
    „Wir geben Qualität einen Namen!“ immer im Vordergrund.
  </p>
);

const text_3 = (
  <p>
    Gleich ob aus Eigenfertigung oder aus zugekauften Teilen: Wir
    übernehmen für Sie die Montage von komplexen Metall- und
    Kunststoffbauteilen oder Systemkomponenten. Mit unserer
    langjährigen Erfahrung finden wir für jede Herausforderung eine
    Lösung.
  </p>
);

const text_4 = (
  <p>
    Wir bieten Ihnen alle wichtigen Leistungen rund um das Thema
    Kanban an. Durch dieses System wird der gesamte Materialfluss
    darauf ausgelegt, dass Ihre Produkte „Just-in-Time“ am Einsatzort
    angeliefert werden. Dadurch erhalten unsere Kunden die
    bestmögliche Flexibilität im Hinblick auf die Liefersicherheit und
    verringern sowohl die eigenen Lagerbestände, als auch die
    internen Durchlaufzeiten.
  </p>
);

const text_5 = (
  <p>
    Haben Sie schon einmal über einen Inhouse-Service nachgedacht?
    Unsere qualifizierten Mitarbeiter unterstützen Sie bei Ihren
    unternehmensinternen Prozessen direkt vor Ort, um Zeit und
    Kosten einzusparen.
  </p>
);

export function Services() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <React.Fragment>
      <div className={"servicesBackground"} style={{ backgroundImage: `url(${Background})` }} />
      <div id={"leistungen"} className={"services"}>
        <div className={"container"}>
          <div className={"servicesTop"}>
            <h1>Dienstleistungen</h1>
            <p>
              Wir haben uns auf die Fertigung und Konfektionierung von Klein-
              und Mittelserien für die Kunststoff- und Metallverarbeitende
              Industrie spezialisiert. Neben verschiedensten Aufgaben im
              Bereich der Konfektionierung von Baugruppen bekannter
              Hersteller bearbeiten wir Metall- und Kunststoffrohlinge bis hin
              zum Finish. Namenhafte Partner aus Industrie und Handel
              gehören zu unseren Kunden. Moderne CNC-Fräs.- und NC-
              Drehmaschinen erlauben uns die Umsetzung kompliziertester
              Kundenanforderungen, bei denen wir auch anspruchsvolle
              Maßtoleranzen sicher einhalten. Und natürlich ist die industrielle
              Nacharbeit für uns kein Fremdwort.
              Unsere Leistungen im Detail:
            </p>
          </div>
          <div className={"servicesBottom"}>
            {["Zerspanungstechnik", "Qualitätsprüfung", "Montage", "Kanban", "Inhouse-Service"].map((value, idx) => (
              <button key={`servicesTabButton__${idx}`}
                      className={`servicesTabButton ${activeTab === idx ? "active" : ""}`}
                      onClick={() => setActiveTab(idx)}>{value}</button>
            ))}

            {[text_1, text_2, text_3, text_4, text_5].map((value, idx) => (
              <div key={`servicesTabContent__${idx}`}
                   className={`servicesTabContent ${activeTab === idx ? "active" : ""}`}>
                {value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function ServicesAsIcons() {
  return (
    <div className={"servicesAsIcons"}>
      <div className={"container"}>
        {[[Montage, "Montage"], [Outsourcing, "Outsourcing"], [Verpackung, "Verpackung"], [zerspanen, "zerspanen"]].map((icon, idx) => (
          <div key={`serviceAsIcon__${idx}`} className={"servicesAsIcons__iconWrapper"}>
            <div className={"servicesAsIcons__icon"}>
              <img src={icon[0]} alt={""} />
            </div>
            <div className={"servicesAsIcons__iconText"}>{icon[1]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
