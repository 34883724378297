import React from "react";
import { Welcome } from "../components/welcome/welcome";
import { Services } from "../components/services/services";
// import { ServicesAsIcons } from "../components/services/services";
import { AboutUs } from "../components/aboutUs/aboutUs";
import { QualityPolicy } from "../components/qualityPolicy/qualityPolicy";
import { References } from "../components/references/references";
import { ContactForm, ContactRequestOffer, GoogleMaps } from "../components/contact/contact";

export default function Home() {
  return (
    <React.Fragment>
      <Welcome />
      <Services />
      {/*<ServicesAsIcons />*/}
      <AboutUs />
      <QualityPolicy />
      <References />
      <ContactRequestOffer />
      <ContactForm />
      <GoogleMaps isMarkerShown />
    </React.Fragment>
  );
}
