import "./header.scss";

import React, { useState } from "react";
import { email, phone } from "../../config";
// import Logo from "../../assets/icons/logo.png";
import LogoBig from "../../assets/icons/logo_new.png";
import Tuev from "../../assets/icons/Tuev.png";
import Certificate from "../../assets/certificate/57842ms_de.pdf";
import Video from "../../assets/video/Konci2019.mp4";
import zenscroll from "zenscroll";
import {Helmet} from "react-helmet";

export function SiteHeader() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Konci | Ihr Partner für industrielle Nacharbeit</title>
      </Helmet>
      <header className={"siteHeader"}>
        <TopHeader />
        <MainHeader />
      </header>
      <HeroBanner />
    </React.Fragment>
  );
}

export function TopHeader() {
  return (
    <div className={"topHeader"}>
      <div className={"container"}>
        <span><i className={"fas fa-phone"} />{phone}</span>
        <span><i className={"fas fa-envelope"} />{email}</span>
      </div>
    </div>
  );
}

export function MainHeader() {
  const [linkActive, setLinkActive] = useState("/");
  const [mobileNavActive, setMobileNavState] = useState(false);

  const handleNavigation = (event, link) => {
    event.preventDefault();
    setLinkActive(link);

    const anchor = document.querySelector(link);

    zenscroll.toY(anchor.offsetTop - 135);

    if(window.history.pushState) {
      window.history.pushState(null, null, link);
    } else {
      window.location.hash = link;
    }

    setMobileNavState(false);
  };

  return (
    <div className="mainHeader">
      <div className={"container"}>
        <div className={"logoContainer"}>
          <img className={"logo"} src={LogoBig} alt={""} />
          <div className={"slogan"}>Wir geben Qualität einen Namen!</div>
        </div>

        <button className={"navButton"} onClick={() => {setMobileNavState(!mobileNavActive)}}>
          <span className={"fas fa-bars"} />
        </button>

        <div className={"tuevLogoMobile"}>
          <img alt="" src={Tuev} />
        </div>

        <nav className={`navigation ${mobileNavActive ? "active" : ""}`}>
          <a
            href="#start"
            className={linkActive === "/" ? "active" : ""}
            onClick={(event) => handleNavigation(event, "#start")}
          >Start</a>
          {["Leistungen", "Unternehmen", "Qualitätspolitik", "Referenzen", "Kontakt"].map(function (value, idx) {
            const link = `#${value.toLowerCase().replace("ä", "ae")}`;

            return (
              <a
                key={`navUrl__${idx}`}
                href={link}
                className={linkActive === link ? "active" : ""}
                onClick={(event) => handleNavigation(event, link)}
              >{value}</a>
            );
          })}

          <a className={"tuevLogo"} href={Certificate} target={"_blank"} rel={"nofollower"}>
            <img alt="" src={Tuev} />
          </a>
        </nav>
      </div>
    </div>
  );
}

export function HeroBanner() {
  return (
    <div id={"start"} className={"heroBanner"}>
      <video preload="yes" autoPlay muted loop playsInline>
        <source src={Video} type={"video/mp4"} />
      </video>
    </div>
  );
}