import "./welcome.scss";

import React from "react";
import KonciVideo from "../../assets/video/Konci2019.mp4";

export function Welcome() {
  return (
    <div className={"welcome"}>
      <div className={"container"}>
        <div className={"welcomeLeft"}>
          <h1>Willkommen</h1>
          <p>
            Herzlich willkommen auf der Internetpräsenz unseres Unternehmens. Seit 2001 sind wir ein verlässlicher
            Partner der Kunststoff– und Metallverarbeitenden Industrie. Unser Unternehmen hat sich auf die Fertigung und
            Konfektionierung von Klein- und Mittelserien spezialisiert. Auf den nachfolgenden Seiten stellen wir Ihnen
            das Unternehmen und unsere Leistungen ausführlich vor. Qualität und Leidenschaft steht bei allem, was wir
            tun, an erster Stelle.
          </p>
        </div>
        <div className={"welcomeRight"}>
          <video src={KonciVideo} loop={false} muted={false} controls />
        </div>
      </div>
    </div>
  );
}
