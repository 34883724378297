import "./qualityPolicy.scss";

import React from "react";
import Certificate from "../../assets/certificate/57842ms_de.pdf";
import Tuev from "../../assets/icons/Tuev.png";

export function QualityPolicy() {
  return (
    <div id={"qualitaetspolitik"} className={"qualityPolicy"}>
      <div className={"container"}>
        <h1>Qualitätspolitik</h1>

        <p>
          Wir sind zertifiziert ...
          <strong> Unser von der TÜV Süd zertifiziertes Managementsystem
                   gibt Ihnen die Sicherheit höchster Prozessqualität. </strong>
          Es wird bescheinigt, dass das Unternehmen KonCi ein
          Managementsystem in Übereinstimmung
          mit dem Standard DIN <a href={Certificate} target={"_blank"} rel={"nofollow"}>ISO 9001:2015</a> (<a href={Certificate} target={"_blank"} rel={"nofollow"}>PDF</a>) für den folgenden Geltungsbereich
          anwendet: Nacharbeit zerspanen, Montage, Verpackung und
          Qualitätsprüfungen nach Kundenvorgaben.
        </p>

        <p>
          Aufgrund der regelmäßigen Überwachung unseres
          Qualitätsmanagementsystems nach ISO 9001:2015, ist eine
          permanente Prozessoptimierung im operativen und strategischen
          Umfeld für uns maßgeblich. Die Verbesserung und
          Weiterentwicklung unseres Qualitätsmanagementsystems sind
          unabdingbare Ziele.
        </p>

        <p style={{textAlign: "center"}}>
          <a href={Certificate} target={"_blank"} rel={"nofollow"}><img src={Tuev} alt={""} /></a>
        </p>
      </div>
    </div>
  );
}
