import './styles/app.scss';

import React from 'react';
import { SiteHeader } from "./components/header/header";
import { SiteFooter } from "./components/footer/footer";
import Home from "./containers/Home";

function App() {
  return (
    <div>
      <SiteHeader />
      <div className="content">
        <Home />
      </div>
      <SiteFooter />
    </div>
  );
}

export default App;
