import "./aboutUs.scss";

import React from "react";

import Image_1 from "../../assets/images/MG_0575.jpg";
import Image_1_large from "../../assets/images/MG_0575_large.jpg";
import Image_2 from "../../assets/images/MG_0615.jpg";
import Image_2_large from "../../assets/images/MG_0615_large.jpg";
import Image_3 from "../../assets/images/MG_0634.jpg";
import Image_3_large from "../../assets/images/MG_0634_large.jpg";
import Image_4 from "../../assets/images/MG_01.jpg";
import Image_4_large from "../../assets/images/MG_01_large.jpg";
import Image_5 from "../../assets/images/MG_02.jpg";
import Image_5_large from "../../assets/images/MG_02_large.jpg";
import Image_6 from "../../assets/images/MG_03.jpg";
import Image_6_large from "../../assets/images/MG_03_large.jpg";

import ModalImage from "react-modal-image";

export function AboutUs() {
  return (
    <div id={"unternehmen"} className={"aboutUs"}>
      <div className={"container"}>
        <h1>Über uns</h1>

        <p>
          Im Jahr 2001 öffnete unser Unternehmen seine Tore in Bielefeld.
          Fast 20 Jahre später können wir mit Stolz auf eine großartige
          Wachstums- und Erfolgsgeschichte mit jährlich neuen Erfolgen
          zurückblicken, wie der Einführung eines
          Qualitätsmanagementsystems nach ISO 9001:2015.
          Der nachhaltige Erfolg unseres Unternehmens wäre ohne
          hochmotivierte Mitarbeiterinnen und Mitarbeiter nicht denkbar.
          Unser mittlerweile zwölfköpfiges Team arbeitet im flexiblen
          Schichtsystem sechs Tage pro Woche, damit wir die Wünsche
          unserer Kunden jederzeit flexibel Just-in-Time erfüllen können.
          Die ständige Aus- und Weiterbildung unseres Teams ist dabei für
          uns eine Selbstverständlichkeit.
          Mit unbedingtem Willen zu höchster Qualität, kontinuierlicher
          Prüfung und Verbesserung unserer Arbeitsprozesse und absoluter
          Termintreue schaffen wir Vertrauen bei unseren Kunden.
        </p>

        <div className={"aboutUsGallery"}>
          {[
            [ Image_1, Image_1_large ],
            [ Image_2, Image_2_large ],
            [ Image_3, Image_3_large ],
            [ Image_4, Image_4_large ],
            [ Image_6, Image_6_large ],
            [ Image_5, Image_5_large ],
          ].map((image, idx) => (
            <ModalImage
              key={`aboutUsImage__${idx}`}
              small={image[0]}
              large={image[1]}
              hideDownload={true}
              alt=""
            />
          ))}
        </div>
      </div>
    </div>
  );
}