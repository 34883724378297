import "./contact.scss";
import React, { useState } from "react";
import { email, phone } from "../../config";
import { compose, withProps } from "recompose";
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "@syncromatics/react-google-maps";
import InfoBox from "@syncromatics/react-google-maps/lib/components/addons/InfoBox";
import ReCAPTCHA from "react-google-recaptcha";

export function ContactRequestOffer() {
  return (
    <div className={"contactRequestOffer"}>
      <div className={"container"}>
        <p>Fordern Sie ein kostenloses Angebot an!</p>
        <a className={"contactRequestOfferButton"} href={"mailto:" + email}>
          E-Mail <i className={"fas fa-chevron-right"} />
        </a>
      </div>
    </div>
  );
}

const ContactInfo = (
  <React.Fragment>
    <p>
      <i className={"fas fa-home"} /><br />
      Krackser Str. 12<br />
      33659 Bielefeld
    </p>

    <p>
      <i className={"fas fa-comment"} /><br />
      Telefon: {phone}<br />
      Fax: 0521 / 42 86 23 69<br />
      Mobil: 0175 / 4 64 05 60<br />
      E-Mail: {email}
    </p>
  </React.Fragment>
);

export function ContactForm() {
  const [isCaptchaValid, setCaptchaValid] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [fieldEmail, setFieldEmail] = useState("");
  const [fieldText, setFieldText] = useState("");
  const [fieldPrivacy, setFieldPrivacy] = useState("");
  const [error, setError] = useState("");
  const [sendSuccess, setSendSuccess] = useState(null);
  const [sendFailed, setSendFailed] = useState(null);

  const onChange = (value) => {
    setCaptchaValid(value);

    if (value) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (isCaptchaValid && fieldName && fieldEmail && fieldPrivacy) {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://www.konci.de/sendMessage.php", true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

      let data = `captcha=${isCaptchaValid}`;
      data = `${data}&name=${fieldName}`;
      data = `${data}&email=${fieldEmail}`;
      data = `${data}&privacyChecked=${fieldPrivacy}`;
      data = `${data}&text=${fieldText}`;

      xhr.send(data);

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (this.status === 200) {
            setSendSuccess(true);
          } else {
            setSendFailed(false);
          }
        }
      };

      if (xhr.status === 200) {
        setSendSuccess(true);
      } else {
        setSendFailed(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div id={"kontakt"} className={"contactForm"}>
      <div className={"container"}>
        <h1>Kontakt</h1>

        <p>
          Haben wir Ihr Interesse geweckt? Gerne erstellen wir Ihnen ein
          unverbindliches Angebot oder bieten Ihnen eine Arbeitsprobe an,
          damit Sie sich von unserer Qualität und Zuverlässigkeit
          überzeugen können.
          Wir würden uns freuen auch Sie bald als unseren Neukunden zu
          begrüßen.
        </p>

        <div className={"contactFormLeft"}>
          {ContactInfo}
        </div>
        <div className={"contactFormRight"}>
          {sendFailed && (
            <h3 className={"textError"}>
              Herzlichen Dank für Ihr Interesse.<br />
              <br />
              Es gab Schwierigkeiten während dem Versenden ihre Kontaktanfrage. Diese konnte deswegen leider nicht
              bearbeitet werden.<br />
              Bitte versuchen Sie es später erneut.
            </h3>
          )}
          {sendSuccess && (
            <h3>Ihre Kontaktanfrage wurde erfolgreich versendet.</h3>
          )}
          {sendFailed === null && sendSuccess === null && (
            <React.Fragment>
              <form>
                <div>
                  <input
                    className={error && !fieldName.length ? "input inputError" : "input"}
                    placeholder={"Name (Pflichtfeld)"}
                    value={fieldName}
                    onChange={(event) => {
                      setFieldName(event.currentTarget.value);
                      setError(false);
                    }}
                  />
                </div>
                <div>
                  <input
                    className={error && !fieldEmail.length ? "input inputError" : "input"}
                    type={"email"}
                    placeholder={"Ihre E-Mail (Pflichtfeld)"}
                    value={fieldEmail}
                    onChange={(event) => {
                      setFieldEmail(event.currentTarget.value);
                      setError(false);
                    }}
                  />
                </div>
                <div>
              <textarea
                className={error && !fieldText.length ? "textarea textareaError" : "textarea"}
                placeholder={"Ihre Nachricht"}
                value={fieldText}
                onChange={(event) => {
                  setFieldText(event.currentTarget.value);
                  setError(false);
                }}
              />
                </div>
                <div>
                  <strong>Nutzungsbedingungen</strong><br />
                  <label
                    className={error && !fieldPrivacy.length ? "labelError" : ""}
                  >
                    <input
                      type="checkbox"
                      name="privacy"
                      checked={fieldPrivacy}
                      onChange={(event) => {
                        setFieldPrivacy(event.currentTarget.checked);
                        setError(false);
                      }}
                    />
                    Ich stimme zu, dass meine Angaben aus dem
                    Kontaktformular zur Beantwortung meiner Anfrage
                    erhoben und verarbeitet werden. Die Daten werden
                    nach abgeschlossener Bearbeitung Ihrer Anfrage
                    gelöscht. Hinweis: Sie können Ihre Einwilligung
                    jederzeit für die Zukunft per E-Mail an
                    info@konci.de widerrufen. Detaillierte
                    Informationen zum Umgang mit Nutzerdaten finden
                    Sie in unserer Datenschutzerklärung.
                  </label>
                </div>
              </form>

              <div className={"submitContainer"}>
                <div className={"submitLeft"}>
                  <ReCAPTCHA
                    sitekey="6LfYjqkUAAAAAOBwa6fMmeZS3ZOLoCtQYF2GmxVY"
                    onChange={onChange}
                  />
                </div>

                <div className={"submitRight"}>
                  {error && (<p className={"formError"}>Bitte füllen Sie zuerst alle Pflichtfelder aus.</p>)}
                  <button className={"contactFormSubmit"} onClick={handleSubmit}>
                    Absenden <i className={"fas fa-chevron-right"} />
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

const location = {
  lat: 51.9612791,
  lng: 8.5217238
};

// const api_key = "AIzaSyDfok7bkYrf71bnlqkou8cMY3KRE45QEUk"; // developer key
const api_key = "AIzaSyBehZArAiTF91reEvKPKRVEgRicIBtZlCY"; // konci.de
// const api_key = "";

export const GoogleMaps = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${api_key}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `440px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: location.lat, lng: location.lng },
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{
      lat: location.lat - 0.00205,
      lng: location.lng
    }}
  >
    {props.isMarkerShown && (
      <React.Fragment>
        <InfoBox
          defaultPosition={new window.google.maps.LatLng(props.center.lat, props.center.lng)}
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
        >
          <div className={"infoBoxContainer"}>
            <div className={"infoBoxText"}>
              {ContactInfo}
            </div>
          </div>
        </InfoBox>
        <Marker position={location} />
      </React.Fragment>
    )}
  </GoogleMap>
);