import "./references.scss";

import React from "react";
import IWN from "../../assets/references/IWN_2.jpeg";
import Hohltraegerfabrik from "../../assets/references/hohltraegerfabrik.png";

export function References() {
  return (
    <div id={"referenzen"} className={"references"}>
      <div className={"container"}>
        <h1>Referenzen</h1>

        <p>
          <span className={"imgReference"}>
            <img src={IWN} width={200} height={"auto"} alt={""} /><br/>
          </span><br/>
          <strong>IWN GmbH & Co. KG</strong>
        </p>

        <p>
          Seit 2018 können wir mit Stolz verkünden, dass wir Zulieferer für die IWN GmbH & Co. KG
          sind. Das Unternehmen hat sich sowohl auf die Fertigung als auch auf die Entwicklung
          komplexer hydraulischer und pneumatischer Anwendungen spezialisiert. Darüber hinaus
          bietet IWN speziell auf Kundenbedürfnisse zugeschnittene Systemlösungen und
          Sonderkomponenten an. Um dem hohen Qualitätsansprüchen der Kunden gerecht zu
          werden, führen wir als TÜV zertifizierter Partner Sicht- und Qualitätsprüfungen für IWN
          durch.<br/>
          <br/>
          Weitere Informationen finden Sie unter<br/>
          <br/>
          <a href={"//www.iwn.de"} rel={"nofollow noopener"}>www.iwn.de</a>
        </p>

        <div style={{height: 50}} />

        <p>
          <span className={"imgReference"}>
            <img src={Hohltraegerfabrik} height={"auto"} alt={""} /><br/>
          </span><br/>
          <strong>Bielefelder Hohlträgerfabrik GmbH</strong>
        </p>

        <p>
          Weitere Informationen finden Sie unter<br/>
          <br/>
          <a href={"//www.bielefelder-hohltraegerfabrik.de/"} rel={"nofollow noopener"}>www.bielefelder-hohltraegerfabrik.de</a>
        </p>
      </div>
    </div>
  );
}
